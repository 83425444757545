import { createFeatureSelector, createSelector } from "@ngrx/store";
import { environment } from "../../../../environments/environment";
import { WELCOME_MESSAGE_STATE_FEATURE_KEY, WelcomeMessageState } from "./welcome-message";

export const selectWelcomeMessageState = createFeatureSelector<WelcomeMessageState>(WELCOME_MESSAGE_STATE_FEATURE_KEY);

export const selectAppInfoForWelcomDialog = createSelector(
    selectWelcomeMessageState,
    (state: WelcomeMessageState): string | null => {

        const currentState = environment.buildVersion;
        const wasNotClosedBefore = state.closedMessageId !== currentState;
        // TODO: Magic symbol - introduce meaningul constant
        const isNotSkipWindow = state.closedMessageId !== '*';

        return wasNotClosedBefore && isNotSkipWindow ? currentState : null;
    });
