@if (appVersion$ | async; as appVersion) {
  <kendo-dialog class="dialog-mask">
    <div class="message" aogTestId="welcome-message" [attr.message-id]="appVersion">
      <h1>Welcome to AnyOrg version {{appVersion}}.</h1>
      <p>We are thrilled to introduce the latest version of AnyOrg, packed with exciting feature enhancements.</p>
      <p>Please access the release notes <a href="https://wiki.swisscom.com/x/aLf9Ow" target="_blank"
      rel="noopener noreferrer">here</a>.
    </p>
    <p>We hope you enjoy using AnyOrg and find the new features valuable.</p>
    <p>Thank you for your continued support and, please feel free to send your feedback and suggestions to our service
      desk.
    </p>
    <button class="close-button" (click)="onClose(appVersion)" kendoButton themeColor="primary" aogTestId="welcome-message-close">
      Close
    </button>
  </div>
</kendo-dialog>
}
