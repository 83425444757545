import { NgModule } from '@angular/core';
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../../shared/shared.module";
import { LocalStorageService } from '../../core/services/local-storage.service';
import {
  WELCOME_MESSAGE_LOCAL_STORAGE_KEY,
  WELCOME_MESSAGE_STATE_CONFIG_TOKEN,
  WELCOME_MESSAGE_STATE_FEATURE_KEY,
} from "./state/welcome-message";
import {
  welcomeMessageRootReducer,
  welcomeMessageStateConfigFactory,
} from "./state/welcome-message.reducer";
import { WelcomeDialogComponent } from './welcome-message/welcome-dialog.component';

@NgModule({
    imports: [
        StoreModule.forFeature(WELCOME_MESSAGE_STATE_FEATURE_KEY, welcomeMessageRootReducer, WELCOME_MESSAGE_STATE_CONFIG_TOKEN),
        SharedModule,
        WelcomeDialogComponent,
    ],
    exports: [
        WelcomeDialogComponent,
    ],
    providers: [
        {
            provide: WELCOME_MESSAGE_LOCAL_STORAGE_KEY,
            useValue: '__welcomeMessageState_storage__',
        },
        {
            provide: WELCOME_MESSAGE_STATE_CONFIG_TOKEN,
            deps: [
                WELCOME_MESSAGE_LOCAL_STORAGE_KEY,
                LocalStorageService,
            ],
            useFactory: welcomeMessageStateConfigFactory,
        },
    ],
})

export class WelcomeMessageModule { }
