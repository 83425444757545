import { Action, StoreConfig, createReducer, on } from "@ngrx/store";
import { StorageWrapper } from "src/app/core/abstractions/storage-wrapper";
import { persistantStorageMetaReducerFactory } from "src/app/core/meta-reducers/local-storage.reducers";
import { WelcomeMessageState } from "./welcome-message";
import { hideWelcomeMessage } from "./welcome-message.actions";

export const welcomeMessageDefaultState: WelcomeMessageState = {
  closedMessageId: '',
};

export const welcomeMessageReducer = createReducer(
  welcomeMessageDefaultState,
  on(hideWelcomeMessage, (state, { messageId }): WelcomeMessageState => ({
    ...state,
    closedMessageId: messageId,
  })),
)

export function welcomeMessageRootReducer(
  state: WelcomeMessageState | undefined,
  action: Action,
): WelcomeMessageState {
  return welcomeMessageReducer(state, action);
}

export const welcomeMessageStateConfigFactory = (
  localStorageKey: string,
  storageWrapper: StorageWrapper,
): StoreConfig<WelcomeMessageState> => ({
  metaReducers: [
    persistantStorageMetaReducerFactory<WelcomeMessageState>(localStorageKey, storageWrapper),
  ],
});

