import { createAction, props } from "@ngrx/store";

export const hideWelcomeMessage = createAction(
    '[Welcome Message] Hide message',
    props<{ messageId: string }>(),
);

export const showWelcomeMessage = createAction(
    '[Welcome Message] Show message',
);

