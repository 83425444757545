import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { DialogComponent } from '@progress/kendo-angular-dialog';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { hideWelcomeMessage } from '../state/welcome-message.actions';
import { selectAppInfoForWelcomDialog } from '../state/welcome-message.selector';
import { TestIdDirective } from '../../../shared/directives/test-id.directive';

@Component({
    selector: 'aog-welcome-dialog',
    templateUrl: './welcome-dialog.component.html',
    styleUrls: ['./welcome-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    DialogComponent,
    TestIdDirective,
    ButtonComponent,
    AsyncPipe,
],
})
export class WelcomeDialogComponent {

  public readonly appVersion$ = this.store.select(selectAppInfoForWelcomDialog);

  public constructor(
    public readonly store: Store,
  ) { }

  public onClose(messageId: string) {
    this.store.dispatch(hideWelcomeMessage({ messageId }));
  }
}
